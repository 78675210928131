<template>
    <div v-loading.fullscreen.lock="loading">
        <product-search-bar-component
            add_auth="新增产品"
            :AllClient="AllClient"
            @search="get_product_index"
            @addTeam="addTeam"
        ></product-search-bar-component>
        <common-table-component
            details_auth="产品详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_product"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_product_index"
        ></common-page-component>
        <product-edit-component
            edit_auth="修改产品"
            del_auth="删除产品"
            status_auth="修改产品状态"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :product_details_data="fproduct_details_data"
            :AllClient="AllClient"
            @exitProductDialog="dialogExit"
            @search="get_product_index"
            @show_edit="show_edit"
            @details_row="details_product"
        ></product-edit-component>
    </div>
</template>

<script>
import { product_index_request, product_details_request } from '@/network/WareHouse/product.js'
import { client_list_request } from '@/network/list.js'

import ProductSearchBarComponent from '@/components/WareHouse/Product/ProductSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import ProductEditComponent from '@/components/WareHouse/Product/ProductEditComponent'

export default {
    name: 'ProductView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                with_client_id: '',
                status: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '产品名称',
                    minWidth: '300px',
                },
                {
                    prop: 'with_client_name',
                    label: '所属客户',
                    minWidth: '280px',
                },
                {
                    prop: 'status',
                    label: '状态',
                    minWidth: '100px',
                    html: (row) => {
                        if(row['status']===0) {
                            return `<div class="cell"><span class="el-tag el-tag--danger el-tag--plain">禁用</span></div>`
                        } else {
                            return `<div class="cell"><span class="el-tag el-tag--success el-tag--plain">正常</span></div>`
                        }
                    }
                },
            ],
            fproduct_details_data: {},
            AllClient: []
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_product_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.with_client_id = param.with_client_id ?? this.cond.with_client_id
            this.cond.status = param.status ?? this.cond.status
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            product_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_product(id) {
            this.fid = id
            this.fdialogFormVisible = true
            product_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fproduct_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_all_client() {
            this.loading = true
            client_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.AllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_product_index()
        this.get_all_client()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        ProductSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        ProductEditComponent,
    },
    watch: {},
}
</script>

<style lang='less'></style>